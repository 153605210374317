<template>
  <div class="aali_tm_section" id="about">
    <div class="aali_tm_about">
      <div class="about_inner">
        <div class="left">
          <div class="image">
            <img src="img/thumbs/45-49.jpg" alt="" />
            <div class="main" data-img-url="img/about/1.jpg"></div>
            <span
              class="square moving_effect"
              data-direction="y"
              data-reverse="yes"
            ></span>
            <span class="border"
              ><span
                class="in moving_effect"
                data-direction="x"
                data-reverse="yes"
              ></span
            ></span>
          </div>
        </div>
        <div class="center">
          <div class="title wow fadeInUp" data-wow-duration="1s">
            <span class="small">稍微自我介紹</span>
            <h3><span>我是一名 Developer</span></h3>
            <h3><span></span></h3>
            <!-- <span class="subtitle">目前位於臺灣臺北</span> -->
          </div>
          <div
            class="text wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <!-- <p>
              嗨! 我叫將生. 我是名前端工程師, 畢業於台藝大。做事積極且樂觀，熱愛學習新的事物，有過人的毅力，對產出品質有一定的堅持。
            </p> -->
            <p>
              於十來年前飄揚過海來台，在北部某大學完成碩士學位，目前在臺北，擁有多年工作經驗。曾於其他國家展出作品並獲獎。
              喜歡什麽都接觸一點看看。
            </p>
          </div>
          <div
            class="aali_tm_button wow fadeInUp"
            data-wow-duration="1.5s"
            data-wow-delay="0.2s"
          >
            <!-- <a href="img/cv/cv.pdf" download class="download">
              <span v-html="download"> </span
            ></a> -->
          </div>
        </div>
        <div class="right">
          <div class="image">
            <img src="img/thumbs/41-61.jpg" alt="" />
            <div class="main" data-img-url="img/about/2.jpg"></div>
            <div
              class="square moving_effect"
              data-direction="x"
              data-reverse="no"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { download } from "./svgImage";
export default {
  name: "About",
  data() {
    return {
      download: `下載履歷 ${download}`,
    };
  },
};
</script>
