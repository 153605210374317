<template>
  <div class="aali_tm_section">
    <div class="aali_tm_resume">
      <div class="content">
        <div class="container">
          <div class="resume_in">
            <div
              class="aali_tm_main_title"
              data-text-align="left"
              data-color="dark"
            >
              <span>瞭解更多</span>
              <h3>我的經歷</h3>
              <p>
                不同的過往，造就當下的你
              </p>
            </div>
            <div class="content_inner">
              <ul>
                <li class="wow fadeInLeft" data-wow-duration="1s">
                  <h3 class="main_title"><span>學歷</span></h3>
                  <ul class="list">
                    <li>
                      <div class="list_inner">
                        <span class="icon" v-html="edu"></span>
                        <div class="info">
                          <div class="left">
                            <h3>新媒體藝術學系</h3>
                            <span>國立臺灣藝術大學 碩士</span>
                          </div>
                          <div class="right">
                            <span>2015 - 2018</span>
                          </div>
                        </div>
                        <div class="text award">
                          <p>
                            • 2017 CyberCube新媒體互動展演，臺北<br>
                            • 2017 松菸Lab新主藝－《噶哈巫！斷語？》<br>
                            • 2017 法國威尼斯第十一屆拉古納國際藝術獎，法國<br>
                            • 2017 Hello, Miss Lin數位展覽<br>
                            • 2017 「混種·變異」數位藝術展<br>
                            • 2017 宜蘭獎新媒體藝術入選<br>
                            • 2016 第十一屆404國際電子藝術節<br>
                            • 2016 《情緒載體》公開表演<br>
                            • 2016 《偶》Rehearsal Happening公開表演舞台技術<br>
                            • 2016 臺北數位藝術節入選<br>
                            • 2016 Art Gallery，Siggraph Asia 2016展覽入選<br>
                            • 2016 想像的風景lll《烏托邦旅程的追尋》，瑞士國際數位展入選<br>
                            • 2016 《築格》多媒體動畫藝術學系展<br>
                            • 2016 成大國際研討會展覽入選
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="list_inner">
                        <span class="icon" v-html="edu"></span>
                        <div class="info">
                          <div class="left">
                            <h3>數位學習設計與管理學系</h3>
                            <span>國立嘉義大學 學士</span>
                          </div>
                          <div class="right">
                            <span>2010 - 2014</span>
                          </div>
                        </div>
                        <div class="text award">
                          <p>
                            • 2013 雲端策展頻道首獎
                          </p>
                        </div>
                      </div>
                    </li>
                    <!-- <li>
                      <div class="list_inner">
                        <span class="icon" v-html="edu"></span>
                        <div class="info">
                          <div class="left">
                            <h3>Bachelor of Science</h3>
                            <span>Univercity of Oxford</span>
                          </div>
                          <div class="right">
                            <span>2012 - 2014</span>
                          </div>
                        </div>
                        <div class="text">
                          <p>
                            Aali is a leading web design agency with an
                            award-winning design
                          </p>
                        </div>
                      </div>
                    </li> -->
                  </ul>
                </li>
                <li class="wow fadeInRight" data-wow-duration="1s">
                  <h3 class="main_title"><span>資歷</span></h3>
                  <ul class="list">
                    <li>
                      <div class="list_inner">
                        <span class="icon" v-html="exp"></span>
                        <div class="info">
                          <div class="left">
                            <h3>Product Designer</h3>
                            <span>Easy Computers</span>
                          </div>
                          <div class="right">
                            <span>2018 - running</span>
                          </div>
                        </div>
                        <div class="text">
                          <p>
                            Aali is a leading web design agency with an
                            award-winning design
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="list_inner">
                        <span class="icon" v-html="exp"></span>
                        <div class="info">
                          <div class="left">
                            <h3>UI/UX Expert</h3>
                            <span>eTheme Group</span>
                          </div>
                          <div class="right">
                            <span>2015 - 2018</span>
                          </div>
                        </div>
                        <div class="text">
                          <p>
                            Aali is a leading web design agency with an
                            award-winning design
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="list_inner">
                        <span class="icon" v-html="exp"></span>
                        <div class="info">
                          <div class="left">
                            <h3>Senior Developer</h3>
                            <span>Envato Studio</span>
                          </div>
                          <div class="right">
                            <span>2012 - 2018</span>
                          </div>
                        </div>
                        <div class="text">
                          <p>
                            Aali is a leading web design agency with an
                            award-winning design
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="media" data-background-style="video">
        <!-- Background Styles: "video" and "image" // Also you can use any youtube, vimeo, and local videos -->
        <div
          class="video jarallax"
          data-speed="0"
          data-jarallax-video="https://vimeo.com/337293658"
        ></div>
        <div
          class="image jarallax"
          data-speed="0"
          data-img-url="img/about/2.jpg"
        ></div>
        <span
          class="square moving_effect"
          data-direction="y"
          data-reverse="yes"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
import { jarallaxContent } from "../utils/utils";
import { edu, exp } from "./svgImage";

export default {
  name: "Resume",
  data() {
    return {
      edu,
      exp,
    };
  },
  mounted() {
    jarallaxContent();
  },
};
</script>
