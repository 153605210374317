<template>
  <div class="aali_tm_section help" id="portfolio">
    <div class="aali_tm_portfolio wow fadeInUp" data-wow-duration="1s">
      <div class="container">
        <div class="aali_tm_main_title" data-text-align="center" data-color="light">
          <!-- <span>我曾經的</span> -->
          <h3>精選作品集</h3>
        </div>
        <div class="portfolio_filter">
          <ul>
            <li><a href="#" class="current" data-filter="*">ALL</a></li>
            <li><a href="#" data-filter=".web">WEB</a></li>
            <li><a href="#" data-filter=".web3">WEB3</a></li>
            <li><a href="#" data-filter=".product">PRODUCT</a></li>
            <li><a href="#" data-filter=".event">EVENT</a></li>
            <!-- <li><a href="#" data-filter=".art">INTERACTIVE ART</a></li> -->
            <!-- <li><a href="#" data-filter=".detail">Detail</a></li> -->
          </ul>
        </div>
        <div class="portfolio_list">
          <ul class="gallery_zoom">
            <li v-for="(list, index) in portfolioList" :key="index" class="gallery_item" :class="list.type">
              <div class="list_inner">
                <div class="image">
                  <img src="img/thumbs/1-1.jpg" alt="" />
                  <div class="main bg-style" :style="'background-image:url(' + list.imageUrl + ')'"></div>
                </div>
                <div class="overlay"></div>
                <div v-html="text"></div>
                <div class="details">
                  <span>{{ list.title }}</span>
                  <h3>{{ list.title2 }}</h3>
                </div>
                <a class="aali_tm_full_link portfolio_popup" href="#" @click.prevent="active = index + 1"></a>
                <div :class="`${active === index + 1 ? '' : 'hidden_content'}`">
                  <ModalBox :close="close">
                    <div class="popup_details">
                      <div class="top_image">
                        <img src="img/thumbs/4-2.jpg" alt="" />
                        <a :href="list.link" target="_blank">
                          <div class="main" :style="'background-image:url(' + list.imageUrl + ')'"></div>
                        </a>
                      </div>
                      <div class="portfolio_main_title">
                        <!-- <span>Detail</span> -->
                        <h3>{{ list.title }}</h3>
                        <div></div>
                      </div>
                      <div class="main_details">
                        <div class="textbox">
                          <p class="p-ellipse">
                            {{ list.description }}
                          </p>
                          <div class="skill-box">
                            <h5>Code</h5>
                            <ul>
                              <li v-for="(skill, index) in list.skills" :key="index">
                                {{ skill }}
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="detailbox">
                          <ul>
                            <li>
                              <span class="first">Client</span>
                              <span>{{ list.client }}</span>
                            </li>
                            <li>
                              <span class="first">Category</span>
                              <span><a href="#">{{ list.category }}</a></span>
                            </li>
                            <li>
                              <span class="first">Date</span>
                              <span>{{ list.date }}</span>
                            </li>
                            <li>
                              <span class="first">Link</span>
                              <span>
                                <a :href="list.link" target="_blank" style="font-size: 0.7em;">
                                  {{ list.link }}
                                </a>
                              </span>
                              <!-- <ul class="share">
                                <li>
                                  <a href="#"><img class="svg" src="img/svg/social/facebook.svg" alt="" /></a>
                                </li>
                                <li>
                                  <a href="#"><img class="svg" src="img/svg/social/twitter.svg" alt="" /></a>
                                </li>
                                <li>
                                  <a href="#"><img class="svg" src="img/svg/social/instagram.svg" alt="" /></a>
                                </li>
                              </ul> -->
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="additional_images" v-if="list.additional_images && list.additional_images.length > 0">
                        <ul>
                          <li>
                            <div class="list_inner">
                              <div class="my_image">
                                <img src="img/thumbs/4-2.jpg" alt="" />
                                <div class="main" data-img-url="img/portfolio/web/01.jpg" style="
                                    background-image: url('img/portfolio/web/01.jpg');
                                  "></div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="list_inner">
                              <div class="my_image">
                                <img src="img/thumbs/4-2.jpg" alt="" />
                                <div class="main" data-img-url="img/portfolio/web/02.jpg" style="
                                    background-image: url('img/portfolio/web/02.jpg');
                                  "></div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="list_inner">
                              <div class="my_image">
                                <img src="img/thumbs/4-2.jpg" alt="" />
                                <div class="main" data-img-url="img/portfolio/web/03.jpg" style="
                                    background-image: url('img/portfolio/web/03.jpg');
                                  "></div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </ModalBox>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <MagnificPopUp :modal="modal" :close="closeModal" :name="name" :src="src" />
  </div>
</template>

<script>
import MagnificPopUp from "./MagnificPopUp.vue";
import ModalBox from "./ModalBox.vue";
import { maximize, soundcloud, text, vimeo, youtube } from "./svgImage";
export default {
  name: "Portfolio",
  data() {
    return {
      vimeo,
      youtube,
      soundcloud,
      text,
      maximize,
      // props
      name: "",
      src: "",
      modal: false,
      // Content
      active: 0,
      portfolioList: [
        {
          type: 'product',
          title: 'MEDIMEET',
          title2: '網站系統',
          description: '涉及隱私，僅口述表示',
          imageUrl: 'img/portfolio/product/medimeet.png',
          skills: ['HTML5', 'CSS', 'JAVASCRIPT', 'React', 'JITSI'],
          client: 'TWINBEANS',
          category: 'Product',
          date: '2021/04 - 2023/04',
          link: 'https://medimeet.twinbeans.com.tw/',
          additional_images: [],
        },
        {
          type: 'product',
          title: 'MEDIMANAGENT',
          title2: '網站系統',
          description: '涉及隱私，僅口述表示',
          imageUrl: 'img/portfolio/product/medimanagement.png',
          skills: ['HTML5', 'CSS', 'JAVASCRIPT', 'Vue3', 'JITSI'],
          client: 'TWINBEANS',
          category: 'Product',
          date: '2021/04 - 2023/04',
          link: 'http://medimanagement.twinbeans.com.tw/',
          additional_images: [],
        },
        {
          type: 'product',
          title: 'MEDISTATION',
          title2: '網站系統',
          description: '涉及隱私，僅口述表示',
          imageUrl: 'img/portfolio/product/medistation.png',
          skills: ['HTML5', 'CSS', 'JAVASCRIPT', 'Angular'],
          client: 'TWINBEANS',
          category: 'Product',
          date: '2021/04 - 2023/04',
          link: 'http://medistation.twinbeans.com.tw/',
          additional_images: [],
        },
        {
          type: 'web',
          title: 'Artgital 網站',
          title2: '互動公司官網',
          description: 'artgital 用 art 與 digital 將視覺藝術與數位互動巧妙地結合，以跨領域的溝通與整合，協助品牌打造顛覆以往的創新體驗，讓人們沉浸其中，滿足想像中的互動科技癮！',
          imageUrl: 'img/portfolio/web/artgital.png',
          skills: ['HTML5', 'CSS', 'JAVASCRIPT', 'Vue2'],
          client: 'Artgital',
          category: 'Brand WEB',
          date: '2020/04',
          link: 'https://artgital.com/',
          additional_images: [],
        },
        {
          type: 'web',
          title: 'Uncle Shawn 活動網站',
          title2: '知名連鎖餐飲網站',
          description: '',
          imageUrl: 'img/portfolio/web/bbq.png',
          skills: ['HTML5', 'CSS', 'JAVASCRIPT', 'AXIOS'],
          client: '-',
          category: 'Brand WEB',
          date: '2021/10',
          link: 'https://maqidoknow.com/activity/bbq_202110',
          additional_images: [],
        },
        {
          type: 'web',
          title: 'Pocky 1111 Web Game',
          title2: 'Game WEB',
          description: 'Pocky雙11活動游戲網站',
          imageUrl: 'img/portfolio/web/pocky.jpg',
          skills: ['HTML5', 'CSS', 'JAVASCRIPT', 'CREATEjs'],
          client: '-',
          category: 'Game WEB',
          date: '2021/11',
          link: 'https://maqidoknow.com/activity/pocky_20211/#/',
          additional_images: [],
        },
        {
          type: 'web3',
          title: 'Kryto Ranger',
          title2: 'IDO & DAPP WEB',
          description: '',
          imageUrl: 'img/portfolio/web3/kr.jpg',
          skills: ['HTML5', 'CSS', 'JAVASCRIPT', 'VUE3', 'WEB3js'],
          client: '-',
          category: 'WEB3',
          date: '2022/12',
          link: 'https://krypto-ranger.web.app/',
          additional_images: [],
        },
        {
          type: 'web3',
          title: 'Meta Dancing Crew',
          title2: 'IDO & DAPP WEB',
          description: '',
          imageUrl: 'img/portfolio/web3/mdc.jpg',
          skills: ['HTML5', 'CSS', 'JAVASCRIPT', 'VUE3', 'WEB3js'],
          client: '-',
          category: 'WEB3',
          date: '2022/10',
          link: 'https://testnet-metadancingcrew.web.app/#/',
          additional_images: [],
        },
        {
          type: 'web',
          title: 'Netpute 網站介紹',
          title2: 'Intro Site',
          description: '遊戲廠商可以在於Netpute平台上快速建立虛寶商店，為您提供一個新的營收渠道。',
          imageUrl: 'img/portfolio/web/netpute.png',
          skills: ['HTML5', 'CSS', 'JAVASCRIPT', 'Vue2'],
          client: 'Netpute',
          category: 'WEBSITE',
          date: '2022/08',
          link: 'https://www.netpute.com/',
          additional_images: [],
        },
        {
          type: 'web',
          title: '《圓劇團》台灣當代馬戲劇團官網',
          title2: '劇團網站',
          description: '我們所有的創作行動與觀點皆連結所依附的土地和文化，尋求民間技藝與當代馬戲的實踐與探索，讓創作自然地呈現出有機的人性與溫度。藉由民間技藝與馬戲的演出分享，與民眾彼此連結與對話，以真實又詩意的感受，產生對社會的哲思。重要作品：《手路》、《狂想‧洪通》、《悲傷ㄟ曼波》、《噶哈巫！斷語？》、《如果你還在》。《悲傷ㄟ曼波》曾入選「2020年-台新藝術獎決選入圍」、「松菸Lab新主藝」、「台灣當代文化實驗場CREATORS進駐創作」、「雲門羅曼菲獎助金」、「雲門-創計畫」、「2018-2021文化部扶植青年藝術計畫」、「文化部媒合演藝團隊進駐計畫-總爺藝文中心、蕭壟文化園區」等。',
          imageUrl: 'img/portfolio/web/thunarcircus.png',
          skills: ['HTML5', 'CSS', 'JAVASCRIPT'],
          client: '圓劇團',
          category: 'WEBSITE',
          date: '2019/08',
          link: 'https://thunarcircus.tw/',
          additional_images: [],
        },
        {
          type: 'web',
          title: '簡易活動網站',
          title2: '活動網站',
          description: '',
          imageUrl: 'img/portfolio/web/car.png',
          skills: ['HTML5', 'CSS', 'JAVASCRIPT'],
          client: '十二點原生',
          category: 'WEBSITE',
          date: '2020/08',
          link: 'https://maqidoknow.com/activity/island_20220330/',
          additional_images: [],
        },
        {
          type: 'web',
          title: 'HONDA 互動網站',
          title2: '互動網站',
          description: '《Dream Your Dream  FUN 送祝福抽好禮》互動式賀卡，讓你的祝福動起來！',
          imageUrl: 'img/portfolio/web/honda.png',
          skills: ['HTML5', 'CSS', 'JAVASCRIPT'],
          client: 'HONDA',
          category: 'WEBSITE',
          date: '2020/08',
          link: 'https://www.youtube.com/watch?v=osMbWHbcAdg&ab_channel=ArtgitalChannel',
          additional_images: [],
        },
        {
          type: 'web',
          title: '黑山基地王官網',
          title2: '公司網站',
          description: '以「出奇致勝」做為創意指標，創造豐富有趣的互動體驗，提供高滲透力的數位溝通橋樑',
          imageUrl: 'img/portfolio/web/bm.png',
          skills: ['HTML5', 'CSS', 'JAVASCRIPT'],
          client: 'MountainBlack',
          category: 'WEBSITE',
          date: '2019/05',
          link: 'http://mountain.black/',
          additional_images: [],
        },
        {
          type: 'web',
          title: '頭稱好日子民宿網站',
          title2: '民宿網站',
          description: `很久很久以前—
          有個被遺忘的岬角
          它與繁華留了適當的距離
          夾在廣闊與寧靜之間

          你可能曾經過 但你不曾駐足過
          來吧 走去
          你會一直想要住在`,
          imageUrl: 'img/portfolio/web/lovelive/lovelive.png',
          skills: ['HTML5', 'CSS', 'JAVASCRIPT', 'PHP'],
          client: 'Global Views Monthly',
          category: 'WEBSITE',
          date: '2019/06',
          link: 'https://lovelive.com.tw/',
          additional_images: [],
        },
        {
          type: 'web',
          title: '《歐洲之亂》雜誌專題網站',
          title2: '專題網站',
          description: '2019年3月29日是英國預訂脫歐的日子，但英國國會通過延後脫歐日至6月30日，情勢依舊混沌不明；而法國黃背心運動延燒逾四個月，原本是抗議加增燃料稅，演變為抗議貧富差距，甚至引發了大暴動。',
          imageUrl: 'img/portfolio/web/10.jpg',
          skills: ['HTML5', 'CSS', 'JAVASCRIPT'],
          client: 'Global Views Monthly',
          category: 'WEBSITE',
          date: '2019/04',
          link: 'https://event.gvm.com.tw/201904_Euro_Crisis/?utm_source=OfficialSite&utm_medium=GV_topic&utm_campaign=201904_Euro_Crisis',
          additional_images: [],
        },
        {
          type: 'web',
          title: '《我世代Be Real！》雜誌專題網站',
          title2: '專題網站',
          description: '1990年至2000年出生的90後，包含從高中剛畢業的畢業生，到小有歷練的上班族，宅、厭世、佛系、開放、多元...各式各樣的標籤貼在他們身上，看似矛盾，卻也充分展現90後的不同面貌。有主見、勇於發聲的「我世代」，可能比你想像得還要務實，還要努力。',
          imageUrl: 'img/portfolio/web/09.jpg',
          skills: ['HTML5', 'CSS', 'JAVASCRIPT'],
          client: 'Global Views Monthly',
          category: 'WEBSITE',
          date: '2019/03',
          link: 'https://event.gvm.com.tw/201903_me-generation/?utm_source=OfficialSite&utm_medium=GV_topic&utm_campaign=201903_me-generation',
          additional_images: [],
        },
        {
          type: 'web',
          title: '《你的家鄉會消失嗎？》',
          title2: '專題網站',
          description: '人口老化、人口往都會集中、出生率快速減少，國發會已於2018年底標示出，全臺灣有134個鄉鎮區有地方消滅危機。',
          imageUrl: 'img/portfolio/web/08.jpg',
          skills: ['HTML5', 'CSS', 'JAVASCRIPT'],
          client: 'Global Views Monthly',
          category: 'WEBSITE',
          date: '2019/02',
          link: 'https://event.gvm.com.tw/201902_Placemaking/',
          additional_images: [],
        },
        {
          type: 'web',
          title: '《末班車經濟》專題網站',
          title2: '專題網站',
          description: '金融海嘯十年了，全球將迎來末班車經濟。 在上車前，下車後，你絕不能錯過這些事。',
          imageUrl: 'img/portfolio/web/07.jpg',
          skills: ['HTML5', 'CSS', 'JAVASCRIPT'],
          client: 'Global Views Monthly',
          category: 'WEBSITE',
          date: '2018/12',
          link: 'https://event.gvm.com.tw/trend/2019/index.html',
          additional_images: []
        },
        {
          type: 'web',
          title: '《大學入學指南》專題網站',
          title2: '專題網站',
          description: '每年學測結束後，便是莘莘學子們選填志願的時刻。究竟進入哪個科系最有前景？就讀哪個領域畢業後不用擔心失業？《遠見》帶你全盤掌握政府大力推動的「5+2產業創新計畫」，從職缺、起薪、出路等各面向解析這七大領域，助你順利選擇所愛，愛你所選！',
          imageUrl: 'img/portfolio/web/06.jpg',
          skills: ['HTML5', 'CSS', 'JAVASCRIPT'],
          client: 'Global Views Monthly',
          category: 'WEBSITE',
          date: '2018/11',
          link: 'https://education.gvm.com.tw//university/2019/',
          additional_images: []
        },
        {
          type: 'web',
          title: '《進擊的百日》雜誌專題網站',
          title2: '專題網站',
          description: '2018年九合一大選過後，是臺灣改變的開始。 民意用選票說明期望，期待「對的人」能做出「改變」。 上任剛滿100天的地方首長們，此刻正站在灘頭上， 為下次可能來襲的民意海嘯做好準備。',
          imageUrl: 'img/portfolio/web/05.jpg',
          skills: ['HTML5', 'CSS', 'JAVASCRIPT'],
          client: 'Global Views Monthly',
          category: 'WEBSITE',
          date: '2018/10',
          link: 'https://event.gvm.com.tw/201903_take-office-100-days/#',
          additional_images: []
        },
        {
          type: 'web',
          title: '2018年遠見五星服務獎',
          title2: '專題網站',
          description: '人工智慧當道，「台式服務」如何結合科技，持續提供令人感動的「彈性」與「溫度」，讓消費者感受到「價值」，而非「價格」，將是所有服務業必須面對的全新挑戰。邁入第16屆的《遠見》服務業大調查，今年再度為服務品質打出最新分數，誰會是今年的黑馬？誰又能繼續連莊？',
          imageUrl: 'img/portfolio/web/04.jpg',
          skills: ['HTML5', 'CSS', 'JAVASCRIPT'],
          client: 'Global Views Monthly',
          category: 'WEBSITE',
          date: '2018/09',
          link: 'https://event.gvm.com.tw/service/2018/index.html',
          additional_images: []
        },
        {
          type: 'web',
          title: '《一杯珍奶》雜誌專題網站',
          title2: '專題網站',
          description: '一杯平凡再不過的珍珠奶茶，竟能締造台灣新的經濟奇蹟！任誰也沒想到，這杯國民飲品能賣進世界各大城市，不論是紐約、巴黎、倫敦、東京、上海、首爾、曼谷、吉隆坡，甚至在南非的約翰尼斯堡都可見到珍奶專賣店。《遠見》深入了解後發現，珍奶產業的供應鏈極為神祕低調，這條不為人知的供應鏈竟培養出不少隱形冠軍。由於他們默默的耕耘，創造出台灣全新的產業，《遠見》團隊帶領讀者，見證這個令人激賞的「珍奶奇蹟」！',
          imageUrl: 'img/portfolio/web/03.jpg',
          skills: ['HTML5', 'CSS', 'JAVASCRIPT'],
          client: 'Global Views Monthly',
          category: 'WEBSITE',
          date: '2018/08',
          link: 'https://event.gvm.com.tw/201811_bubble-tea/',
          additional_images: []
        },
        {
          type: 'web',
          title: '《台灣精英爲何要回家》雜誌專題網站',
          title2: '專題網站',
          description: '這些年來，台灣陷入高階人才外流海嘯，但近期至少有上百位旅外精英返台發展。《遠見》獨家專訪近20位回台精英，挖掘遊子歸鄉的五大理由，破解五大出走迷思；還有一群科技人也選擇回台創業、投資，為什麼？他們繞了世界一圈，重新看見哪些台灣優勢？',
          imageUrl: 'img/portfolio/web/02.jpg',
          skills: ['HTML5', 'CSS', 'JAVASCRIPT'],
          client: 'Global Views Monthly',
          category: 'WEBSITE',
          date: '2018/06',
          link: 'https://event.gvm.com.tw/201810_talent-returning/',
          additional_images: []
        },
        {
          type: 'web',
          title: '《誰Fire了台灣老闆》雜誌專題網站',
          title2: '專題網站',
          description: 'New Laptop',
          imageUrl: 'img/portfolio/web/01.jpg',
          skills: ['HTML5', 'CSS', 'JAVASCRIPT'],
          client: 'Global Views Monthly',
          category: 'WEBSITE',
          date: '2018/04',
          link: 'https://event.gvm.com.tw/201809_when-boss-get-fired/',
          additional_images: []
        },
        {
          type: 'event',
          title: 'EQC 400 4MATIC ELECTRIC ART',
          title2: '專案參與',
          description: '兩年一度台北世界新車大展，十二點原生概念以「未來即現 The Future is Now」為主題，依據賓士C.A.S.E策略，打造2030年的未來台北城市風貌，呈現 Mercedes-Benz 對未來移動的願景。總面積750坪，面對十天展期約10萬人參觀人數，十二點原生概念從創意發想到O2O、軟硬體整合、從設計到執行，展現全方位能力，設下世界新車大展新標竿。',
          imageUrl: 'img/portfolio/event/mercedes.png',
          skills: ['HTML5', 'CSS', 'JAVASCRIPT'],
          client: 'Mercedes Benz',
          category: 'EVENT',
          date: '2019/12',
          link: 'https://www.12oclock.com.tw/mercedesbenz-taipei-autoshow-2020',
          additional_images: []
        },
        {
          type: 'event',
          title: 'HELLO MISS LIN',
          title2: '互動體驗裝置',
          description: '「HELLO, MISS LIN跨界女神 數位遶境」是近期剛上線不久的一檔台灣民俗文化展示，從展覽命名到主視覺設計都充滿潮流感，積極跟年輕世代溝通的意圖不証自明。但是，展示團隊能夠在想必極其有限的資源限制裡，舉重若輕地駕馭這麼宏大的主題嗎？主辦單位中華文化總會做為過去長期刻板印象裡專司主流文化價值推廣的意識形態國家機器，有沒有足夠的想像力和開放性來革自己的命，開風氣之先？觀展前我不免心中存疑，或許因為曾經做過幾年博物館相關的文化研究，對於新出爐的展覽，在沒有眼見為憑、以身為度之前，我是個無可救藥、徹頭徹尾的懷疑論者，有著近乎一絲不苟的專業苛求。',
          imageUrl: 'img/portfolio/event/lin.jpg',
          skills: ['Arduino', 'MaxMSP'],
          client: '宜東文化創意有限公司',
          category: 'EVENT',
          date: '2019/12',
          link: 'https://www.thenewslens.com/article/77434',
          additional_images: []
        },
        {
          type: 'event',
          title: 'KOHLER 快閃店',
          title2: '互動體驗裝置',
          description: '美國百年廚衛 KOHLER 快閃店香堤廣場開幕，現場享獨家優惠再抽曼谷自由行',
          imageUrl: 'img/portfolio/event/kohler.jpg',
          skills: ['Arduino', 'MaxMSP'],
          client: 'Kohler',
          category: 'EVENT',
          date: '2019/11',
          link: 'https://www.thenewslens.com/article/77434',
          additional_images: []
        }
      ]
    };
  },
  mounted() {
    let Isotope = require("isotope-layout");
    setTimeout(() => {
      let iso = new Isotope(".gallery_zoom", {
        itemSelector: ".gallery_item",
        layoutMode: "fitRows",
      });
      let filterFns = {
        // show if number is greater than 50
        numberGreaterThan50: function (itemElem) {
          let number = itemElem.querySelector(".number").textContent;
          return parseInt(number, 10) > 50;
        },
        // show if name ends with -ium
        ium: function (itemElem) {
          let name = itemElem.querySelector(".name").textContent;
          return name.match(/ium$/);
        },
      };
      // bind filter button click filter main funtion
      let filtersElem = document.querySelector(".portfolio_filter ul");
      filtersElem.addEventListener("click", function (event) {
        if (event.target.getAttribute("data-filter")) {
          let filterValue = event.target.getAttribute("data-filter");
          filterValue = filterFns[filterValue] || filterValue;
          iso.arrange({ filter: filterValue });
        }
      });
    }, 1000);
  },
  methods: {
    onClick(name, src) {
      this.name = name;
      this.src = src;
      this.modal = true;
    },
    closeModal() {
      this.modal = false;
      this.name = "";
      this.src = "";
    },
    close() {
      this.active = 0;
    },
  },
  components: { MagnificPopUp, ModalBox },
};
</script>
<style scoped>
.bg-style {
  background-color: black;
}

.skill-box ul {
  list-style-type: disclosure-closed !important;
  margin-left: 25px !important;
  width: 100%;
}

.skill-box li {
  float: none !important;
  width: 100% !important;
  margin: 0 !important;
}

.p-ellipse {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.detail-box {
  max-width: 400px;
}
</style>
