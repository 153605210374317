<template>
  <div class="aali_tm_section" id="testimonial">
    <div class="aali_tm_testimonials">
      <div class="left">
        <div class="image">
          <img src="img/about/test.jpg" alt="" />
        </div>
      </div>
      <div class="right">
        <div class="right_in">
          <div
            class="aali_tm_main_title"
            data-text-align="left"
            data-color="light"
          >
            <span>我喜歡的</span>
            <h3>那些名言</h3>
          </div>
          <swiper
            class="owl-carousel ul"
            :options="swiperOptions"
            ref="mySwiper"
          >
            <swiper-slide class="item li">
              <div class="text">
                <p>
                  沒有退路時，潛能就發揮出來了。
                </p>
              </div>
              <div class="name">
                <h3><span></span></h3>
              </div>
            </swiper-slide>
            <swiper-slide class="item li">
              <div class="text">
                <p>
                  人生就像騎腳踏車，想保持平衡就得往前走。
                </p>
              </div>
              <div class="name">
                <h3>愛因斯坦<span></span></h3>
              </div>
            </swiper-slide>
            <swiper-slide class="item li">
              <div class="text">
                <p>
                  意志堅強的人，他的世界充滿著無限的可能性。
                </p>
              </div>
              <div class="name">
                <h3><span></span></h3>
              </div>
            </swiper-slide>
          </swiper>
          <div class="direct">
            <a class="prev_button" href="#"><i class="icon-left"></i></a>
            <a class="next_button" href="#"><i class="icon-right"></i></a>
          </div>
        </div>
      </div>
      <span class="border"
        ><span
          class="in moving_effect"
          data-direction="x"
          data-reverse="yes"
        ></span
      ></span>
      <span
        class="square moving_effect"
        data-direction="y"
        data-reverse="yes"
      ></span>
      <span class="quote moving_effect" data-direction="x"
        ><i class="icon-quote-left"></i
      ></span>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
export default {
  name: "Testimonial",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 1,
        loop: true,
        pagination: false,
        navigation: {
          prevEl: ".prev_button",
          nextEl: ".next_button",
        },
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
};
</script>
