var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aali_tm_section"},[_c('div',{staticClass:"aali_tm_resume"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"resume_in"},[_vm._m(0),_c('div',{staticClass:"content_inner"},[_c('ul',[_c('li',{staticClass:"wow fadeInLeft",attrs:{"data-wow-duration":"1s"}},[_vm._m(1),_c('ul',{staticClass:"list"},[_c('li',[_c('div',{staticClass:"list_inner"},[_c('span',{staticClass:"icon",domProps:{"innerHTML":_vm._s(_vm.edu)}}),_vm._m(2),_vm._m(3)])]),_c('li',[_c('div',{staticClass:"list_inner"},[_c('span',{staticClass:"icon",domProps:{"innerHTML":_vm._s(_vm.edu)}}),_vm._m(4),_vm._m(5)])])])]),_c('li',{staticClass:"wow fadeInRight",attrs:{"data-wow-duration":"1s"}},[_vm._m(6),_c('ul',{staticClass:"list"},[_c('li',[_c('div',{staticClass:"list_inner"},[_c('span',{staticClass:"icon",domProps:{"innerHTML":_vm._s(_vm.exp)}}),_vm._m(7),_vm._m(8)])]),_c('li',[_c('div',{staticClass:"list_inner"},[_c('span',{staticClass:"icon",domProps:{"innerHTML":_vm._s(_vm.exp)}}),_vm._m(9),_vm._m(10)])]),_c('li',[_c('div',{staticClass:"list_inner"},[_c('span',{staticClass:"icon",domProps:{"innerHTML":_vm._s(_vm.exp)}}),_vm._m(11),_vm._m(12)])])])])])])])])]),_vm._m(13)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aali_tm_main_title",attrs:{"data-text-align":"left","data-color":"dark"}},[_c('span',[_vm._v("瞭解更多")]),_c('h3',[_vm._v("我的經歷")]),_c('p',[_vm._v(" 不同的過往，造就當下的你 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"main_title"},[_c('span',[_vm._v("學歷")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('div',{staticClass:"left"},[_c('h3',[_vm._v("新媒體藝術學系")]),_c('span',[_vm._v("國立臺灣藝術大學 碩士")])]),_c('div',{staticClass:"right"},[_c('span',[_vm._v("2015 - 2018")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text award"},[_c('p',[_vm._v(" • 2017 CyberCube新媒體互動展演，臺北"),_c('br'),_vm._v(" • 2017 松菸Lab新主藝－《噶哈巫！斷語？》"),_c('br'),_vm._v(" • 2017 法國威尼斯第十一屆拉古納國際藝術獎，法國"),_c('br'),_vm._v(" • 2017 Hello, Miss Lin數位展覽"),_c('br'),_vm._v(" • 2017 「混種·變異」數位藝術展"),_c('br'),_vm._v(" • 2017 宜蘭獎新媒體藝術入選"),_c('br'),_vm._v(" • 2016 第十一屆404國際電子藝術節"),_c('br'),_vm._v(" • 2016 《情緒載體》公開表演"),_c('br'),_vm._v(" • 2016 《偶》Rehearsal Happening公開表演舞台技術"),_c('br'),_vm._v(" • 2016 臺北數位藝術節入選"),_c('br'),_vm._v(" • 2016 Art Gallery，Siggraph Asia 2016展覽入選"),_c('br'),_vm._v(" • 2016 想像的風景lll《烏托邦旅程的追尋》，瑞士國際數位展入選"),_c('br'),_vm._v(" • 2016 《築格》多媒體動畫藝術學系展"),_c('br'),_vm._v(" • 2016 成大國際研討會展覽入選 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('div',{staticClass:"left"},[_c('h3',[_vm._v("數位學習設計與管理學系")]),_c('span',[_vm._v("國立嘉義大學 學士")])]),_c('div',{staticClass:"right"},[_c('span',[_vm._v("2010 - 2014")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text award"},[_c('p',[_vm._v(" • 2013 雲端策展頻道首獎 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"main_title"},[_c('span',[_vm._v("資歷")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('div',{staticClass:"left"},[_c('h3',[_vm._v("Product Designer")]),_c('span',[_vm._v("Easy Computers")])]),_c('div',{staticClass:"right"},[_c('span',[_vm._v("2018 - running")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v(" Aali is a leading web design agency with an award-winning design ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('div',{staticClass:"left"},[_c('h3',[_vm._v("UI/UX Expert")]),_c('span',[_vm._v("eTheme Group")])]),_c('div',{staticClass:"right"},[_c('span',[_vm._v("2015 - 2018")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v(" Aali is a leading web design agency with an award-winning design ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('div',{staticClass:"left"},[_c('h3',[_vm._v("Senior Developer")]),_c('span',[_vm._v("Envato Studio")])]),_c('div',{staticClass:"right"},[_c('span',[_vm._v("2012 - 2018")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v(" Aali is a leading web design agency with an award-winning design ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"media",attrs:{"data-background-style":"video"}},[_c('div',{staticClass:"video jarallax",attrs:{"data-speed":"0","data-jarallax-video":"https://vimeo.com/337293658"}}),_c('div',{staticClass:"image jarallax",attrs:{"data-speed":"0","data-img-url":"img/about/2.jpg"}}),_c('span',{staticClass:"square moving_effect",attrs:{"data-direction":"y","data-reverse":"yes"}})])
}]

export { render, staticRenderFns }