<template>
  <div class="aali_tm_section" id="contact">
    <div class="aali_tm_contact">
      <div class="container">
        <div
          class="aali_tm_main_title"
          data-text-align="center"
          data-color="dark"
        >
          <span>若您有需求</span>
          <h3>歡迎聯繫</h3>
        </div>
        <div class="contact_inner">
          <div class="left wow fadeInLeft" data-wow-duration="1s">
            <div class="text">
              <!-- <p></p> -->
            </div>
            <ul>
              <li>
                <div
                  class="list_inner"
                  v-html="
                    `<span>Line ID</span>
                  <h3>johnsonliew</h3>` + call
                  "
                ></div>
              </li>
              <li>
                <div
                  class="list_inner"
                  v-html="
                    msg +
                    `<span>Email</span>
                  <h3>
                    <a class='line_anim' href='mailto:ran2king06@gmail.com'
                      >ran2king06@gmail.com</a
                    >
                  </h3>`
                  "
                ></div>
              </li>
            </ul>
          </div>
          <div class="right wow fadeInRight" data-wow-duration="1s">
            <div class="fields">
              <form
                class="contact_form"
                id="contact_form"
                @submit.prevent="handleSubmit"
              >
                <div
                  class="returnmessage"
                  data-success="我收到您的email了！謝謝"
                  :style="{
                    display: success ? 'block' : 'none',
                  }"
                >
                  <span class="contact_success">
                    我收到您的email了！謝謝
                  </span>
                </div>
                <div
                  class="empty_notice"
                  :style="{ display: error ? 'block' : 'none' }"
                >
                  <span>請填寫必要欄位</span>
                </div>
                <div class="input_list">
                  <ul>
                    <li>
                      <input
                        id="name"
                        type="text"
                        v-model="fromData.name"
                        placeholder="稱呼"
                      />
                    </li>
                    <li>
                      <input
                        id="email"
                        type="text"
                        v-model="fromData.email"
                        placeholder="Email"
                      />
                    </li>
                    <li>
                      <input
                        id="phone"
                        type="number"
                        placeholder="聯絡方式"
                        name="phn"
                        v-model="fromData.phone"
                      />
                    </li>
                  </ul>
                </div>
                <div class="message_area">
                  <textarea
                    id="message"
                    placeholder="您的留言"
                    v-model="fromData.msg"
                  ></textarea>
                </div>
                <div class="aali_tm_button">
                  <a id="send_message" href="#" @click.prevent="handleSubmit"
                    ><span v-html="'發送訊息' + msgSent"> </span
                  ></a>
                </div>

                <!-- If you want change mail address to yours, just open "modal" folder >> contact.php and go to line 4 and change detail to yours.  -->
              </form>
            </div>
          </div>
        </div>
      </div>
      <span
        class="square moving_effect"
        data-direction="y"
        data-reverse="yes"
      ></span>
    </div>
  </div>
</template>

<script>
import { call, msg, msgSent } from "./svgImage";
export default {
  name: "Contact",
  data() {
    return {
      msgSent,
      call,
      msg,
      fromData: {
        name: "",
        email: "",
        phone: "",
        msg: "",
      },
      error: false,
      success: false,
    };
  },
  methods: {
    handleSubmit() {
      const { name, email, phone, msg } = this.fromData;
      if (name && email && phone && msg) {
        this.success = true;
        setTimeout(() => {
          this.success = false;
        }, 2000);
      } else {
        this.error = true;
        setTimeout(() => {
          this.error = false;
        }, 2000);
      }
    },
  },
};
</script>
